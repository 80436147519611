import React from "react";
import { serverURL, questionnaireId, questionNo } from "./utils/Server";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      identifier: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
  }

  handleIdentifierChange(e) {
    this.setState(() => ({
      identifier: e.target.value,
    }));
  }

  validateEntry = (identifier) => {
    if (identifier.length < 7) return false;
    return true;
  };

  handleClick() {
    if (!this.validateEntry(this.state.identifier)) {
      this.setState(() => ({
        errorMessage: "Identifiant non valide",
      }));
      return;
    }
    this.setState(() => ({
      errorMessage: "",
    }));

    try {
      fetch(
        `${serverURL}/get-pictures?questionnaireId=${questionnaireId}&questionNo=${questionNo}&id=${this.state.identifier}`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.status === 3) {
              this.props.onAuthenticated(
                result.pictures,
                this.state.identifier
              );
              return;
            }
            this.setState(() => ({ errorMessage: result.message }));
            console.log(result);
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(function () {
          console.log("error");
        });
    } catch (error) {
      console.log("error");
    }
  }

  render() {
    return (
      <div>
        {this.state.errorMessage.length > 0 && (
          <Row>
            <Col className="justify-content-center">
              <h3> {this.state.errorMessage} </h3>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <input
              type="text"
              id="identifier"
              value={this.state.identifier}
              onChange={this.handleIdentifierChange}
              placeholder="Votre identifiant.."
              className="col-sm-6 mx-auto"
            ></input>
          </Col>
        </Row>
        <Row>
          <Col>
            <button className="button" onClick={this.handleClick}>
              Accéder
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Login;
