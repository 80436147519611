import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { serverURL, questionnaireId, questionNo } from "./utils/Server";

class PicturesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(index) {
    this.setState({
      activeIndex: index,
    });
  }

  getUrlDownload() {
    const fileName = this.props.pictures[this.state.activeIndex];
    return `${serverURL}/download-file?fileName=${fileName}`;
  }

  getUrlDownloadAll() {
    const id = this.props.identifier;
    return `${serverURL}/download-files-as-zip?questionnaireId=${questionnaireId}&questionNo=${questionNo}&id=${id}`;
  }

  render() {
    return (
      <div>
        <Row className="justify-content-md-center">
          <Col lg="9">
            <Carousel interval={null} onSelect={this.handleSelect}>
              {this.props.pictures.map((picture, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={picture}
                    alt="First slide"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row xs={1} md={3} className="justify-content-md-center">
          <Col lg="3" style={{ marginTop: 10 }}>
            <a
              style={{ marginRight: 10, color: "#fff" }}
              className="button"
              href={this.getUrlDownload()}
              rel="noopener noreferrer"
            >
              Télécharger
            </a>
          </Col>
          <Col lg="3" style={{ marginTop: 10 }}>
            <a
              style={{ marginRight: 10, color: "#fff" }}
              className="button"
              href={this.getUrlDownloadAll()}
              rel="noopener noreferrer"
            >
              Télécharger tout
            </a>
          </Col>
          <Col lg="3" style={{ marginTop: 10 }}>
            <button onClick={() => this.props.onLogOut()} className="button">
              Retour
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PicturesView;
